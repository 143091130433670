//import getters from './getters'
import actions from "./actions";
//import mutations from './mutations'

const state = {
  isRegConfirm: false,
  regData: null,
  isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
  currentUser: {},
  availableTalants: {},
  userTalants: {},
  userTools: {},
  userStyles: {},
  showInputResetCode: false,
  audioFiles: [],
  videoFiles: [
    {
      description: "Подписывайся на канал https://goo.gl/J7qOPL\nВступай, всегда самые новые клипы тут!!! http://vk.com/fresh_clips\nНаш Instagram (инстаграм): instagram.com/freshclipsofficial",
      duration: 322,
      externalFlag: "Y",
      fileName: "Ленинград feat. Глюк’oZa (ft. ST) Жу-Жу (Глюкоза и Leningrad)_1728594761243_1bqlbx",
      fileType: "VIDEO",
      fileURL: "https://vk.com/video_ext.php?oid=-1946517&id=456242972&hash=aaab16361f3d7c16&__ref=vk.api&api_hash=17285947610dff31e41d87e3e7e8_HA3DIMZTGAYTAMY&hd2",
      hostingService: "VK",
      orientation: "Horizontal",
      originName: "Ленинград feat. Глюк’oZa (ft. ST) Жу-Жу (Глюкоза и Leningrad)",
      subType: "VKVideo",
      image:'https://sun6-21.userapi.com/c845121/v845121766/55fe2/5rUv1TiJCiM.jpg'
    },
    {
      description: "Подписывайся на канал https://goo.gl/J7qOPL\nВступай, всегда самые новые клипы тут!!! http://vk.com/fresh_clips\nНаш Instagram (инстаграм): instagram.com/freshclipsofficial",
      duration: 300,
      externalFlag: "Y",
      fileName: "Другой видео файл_1728594761244_2jqlby",
      fileType: "VIDEO",
      fileURL: "https://vk.com/video_ext.php?oid=-1946517&id=456242973&hash=aaab16361f3d7c16&__ref=vk.api&api_hash=17285947610dff31e41d87e3e7e8_HA3DIMZTGAYTBMY&hd2",
      hostingService: "VK",
      orientation: "Horizontal",
      originName: "Другой Видео Название",
      subType: "VKVideo",
      image:'https://sun6-21.userapi.com/c845121/v845121766/55fe2/5rUv1TiJCiM.jpg'
    },
    {
      description: "Подписывайся на канал https://goo.gl/J7qOPL\nВступай, всегда самые новые клипы тут!!! http://vk.com/fresh_clips\nНаш Instagram (инстаграм): instagram.com/freshclipsofficial",
      duration: 280,
      externalFlag: "Y",
      fileName: "Третий видео файл_1728594761245_3kqlcz",
      fileType: "VIDEO",
      fileURL: "https://vk.com/video_ext.php?oid=-1946517&id=456242974&hash=aaab16361f3d7c16&__ref=vk.api&api_hash=17285947610dff31e41d87e3e7e8_HA3DIMZTGAYTCMY&hd2",
      hostingService: "VK",
      orientation: "Horizontal",
      originName: "Третье Видео Название",
      subType: "VKVideo",
      image:'https://sun6-21.userapi.com/c845121/v845121766/55fe2/5rUv1TiJCiM.jpg'
    },
    {
      description: "",
      duration: 21,
      externalFlag: "Y",
      fileName: "Клип @cashbackoff_1728599608856_227a6s",
      fileType: "VIDEO",
      fileURL: "https://vk.com/video_ext.php?oid=-215551349&id=456240057&hash=c158431e6dd7b842&__ref=vk.api&api_hash=1728599608d88db2a9f92e59bca4_HA3DIMZTGAYTAMY&hd2",
      hostingService: "VK",
      orientation: "Vertical",
      originName: "Клип @cashbackoff",
      subType: "VKClip",
      image: "https://i.mycdn.me/getVideoPreview?id=7258144180853&idx=0&type=39&tkn=qYA0zXEAnnUITVUffUJmYY2PEeA&fn=vid_x"
    }
    
  ],
  currentAudioId: null, // ID текущего проигрываемого файла
  audioStatus: 'stopped', // Статус проигрывания ('playing', 'paused', 'stopped')
  audioElement: null, // Элемент аудио
};


const getters = {
  isRegConfirm: (state) => state.isRegConfirm,
  regData: (state) => state.regData,
  isLoggedIn: (state) => state.isLoggedIn,
  currentUser: (state) => state.currentUser,
  availableTalants: (state) => state.availableTalants,
  userTalants: (state) => state.userTalants,
  userTools: (state) => state.userTools,
  userStyles: (state) => state.userStyles,
  userStatus: (state) => state.currentUser.Status,
  showInputResetCode: (state) => state.showInputResetCode,
  startTimer: (state) => state.startTimer,
  audioFiles:(state)=>state.audioFiles,
  videoFiles:(state)=>state.videoFiles
};

const mutations = {
  DELETE_USER_TALANT(state, talantCode) {
    state.userTalants = state.userTalants.filter(talant => talant.Code !== talantCode);
    state.availableTalants.Talants = state.availableTalants.Talants.filter(talent => talent.Code !== talantCode);

  },
  SET_SHOW_INPUT_RESET_CODE(state, value) {
    state.showInputResetCode = value;
  },
  
  SET_REGDATA(state, data) {
    state.regData = data;
  },
  SET_REGCONFIRM(state, status) {
    state.isRegConfirm = status;
  },
  SET_LOGIN(state, status) {
    state.isLoggedIn = status;
    localStorage.setItem('isLoggedIn', status ? 'true' : 'false');
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
  SET_CURRENT_USER_AVAILABLE_TALANTS(state, talants) {
    state.availableTalants = talants;
  },
  SET_CURRENT_USER_TALANTS(state, talants) {
    state.userTalants = talants;
  },
  SET_CURRENT_USER_TOOLS(state, tools) {
    state.userTools = tools;
  },
  SET_CURRENT_USER_STYLES(state, styles) {
    state.userStyles = styles;
  },
  UpdateFirstName(state, value) {
    state.currentUser.FirstName = value;
  },
  UpdateLastName(state, value) {
    state.currentUser.LastName = value;
  },
  UpdateBirthDate(state, value) {
    state.currentUser.BirthDate = value;
  },
  UpdateAbout(state, value) {
    state.currentUser.AboutMe = value;
  },
  UpdateNickName(state, value) {
    state.currentUser.NickName = value;
  },
  UpdateAvatar(state, value) {
    state.currentUser.Avatar = value;
  },
  UpdateSex(state, value) {
    state.currentUser.Sex = value;
  },
  SET_USER_VIDEO(state, videoFiles) {
    state.videoFiles = videoFiles;
  },

  SET_USER_AUDIO(state, audioFiles) {
    console.log('audioFiles',audioFiles)
    // Сортируем файлы перед установкой
    state.audioFiles = audioFiles.sort((a, b) => {
      let dateA = new Date(a.AddedDT);
      let dateB = new Date(b.AddedDT);
      return dateB - dateA; // От нового к старому
    });
  },
  

  SET_AUDIOSTATUS(state, { fileId, status, time }) {
    console.log('Setting audio status for FileId:', fileId, 'status:', status);
    if (state.audioFiles) {
      state.audioFiles.forEach((item) => {
        if (item.FileId === fileId) {
          item.audioStatus = status;
          if (time !== undefined) {
            item.currentTime = time;
          }
        } else {
          item.audioStatus = 'stopped';
        }
      });
    } else {
      console.warn("Audio files not found.");
    }
  },
  
  
  UpdateTripFlag(state, value) {
    state.currentUser.TripFlag = value;
  },
  UpdateRemoteFlag(state, value) {
    state.currentUser.RemoteFlag = value;
  },
  UpdateAvatarId(state, value) {
    state.currentUser.AvatarId = value;
  },
}  
export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
